import React from "react";

interface props {
	color: string;
}

export default (props: props): JSX.Element => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 1"
			viewBox="0 0 826.063 335.01"
		>
			<path
				fill="#f1f1f1"
				d="M79.596 290.018l-1.3-1.518c11.008-9.435 24.71-21.177 29.839-36.697 5.055-15.299 1.252-33.769-9.925-48.202-9.513-12.284-24.2-21.957-42.477-27.973-3.605-1.187-7.348-2.241-10.967-3.261-8.75-2.464-17.797-5.013-25.809-9.67C7.922 156.28-2.102 143.68.382 129.99a23.856 23.856 0 0117.156-18.508l.532 1.929a21.86 21.86 0 00-15.723 16.95c-2.308 12.722 7.184 24.542 17.615 30.606 7.793 4.53 16.717 7.044 25.346 9.475 3.64 1.025 7.405 2.086 11.05 3.286 18.657 6.142 33.676 16.048 43.433 28.648 11.58 14.953 15.504 34.133 10.243 50.055-5.304 16.048-19.24 27.991-30.438 37.587z"
			></path>

			<ellipse
				cx="217.327"
				cy="393.296"
				fill="#f1f1f1"
				rx="17.5"
				ry="8.5"
				transform="rotate(-12.192 -1198.747 1127.4)"
			></ellipse>

			<ellipse
				cx="242.838"
				cy="445.076"
				fill="#f1f1f1"
				rx="17.5"
				ry="8.5"
				transform="rotate(-12.192 -1173.237 1179.18)"
			></ellipse>

			<ellipse
				cx="213.46"
				cy="460.631"
				fill="#f1f1f1"
				rx="17.5"
				ry="8.5"
				transform="rotate(-12.192 -1202.614 1194.735)"
			></ellipse>

			<path
				fill="#e5e5e5"
				d="M272.226 178.204H274.226V184.204H272.226z"
			></path>

			<path
				fill="#e5e5e5"
				d="M274.226 312.044h-2V299.26h2zm0-25.568h-2v-12.784h2zm0-25.568h-2v-12.784h2zm0-25.568h-2v-12.784h2zm0-25.568h-2v-12.784h2z"
			></path>

			<path
				fill="#e5e5e5"
				d="M272.226 324.828H274.226V330.828H272.226z"
			></path>

			<path
				fill="#e5e5e5"
				d="M491.226 178.204H493.226V184.204H491.226z"
			></path>

			<path
				fill="#e5e5e5"
				d="M493.226 312.044h-2V299.26h2zm0-25.568h-2v-12.784h2zm0-25.568h-2v-12.784h2zm0-25.568h-2v-12.784h2zm0-25.568h-2v-12.784h2z"
			></path>

			<path
				fill="#e5e5e5"
				d="M491.226 324.828H493.226V330.828H491.226z"
			></path>

			<path
				fill="#e5e5e5"
				d="M601.695 60.828H603.695V66.828H601.695z"
			></path>

			<path
				fill="#e5e5e5"
				d="M603.695 312.542h-2v-12.285h2zm0-24.57h-2v-12.287h2zm0-24.573h-2v-12.285h2zm0-24.571h-2v-12.286h2zm0-24.571h-2V201.97h2zm0-24.572h-2v-12.286h2zm0-24.571h-2v-12.286h2zm0-24.572h-2v-12.285h2zm0-24.571h-2v-12.286h2zm0-24.572h-2V79.114h2z"
			></path>

			<path
				fill="#e5e5e5"
				d="M601.695 324.828H603.695V330.828H601.695z"
			></path>

			<path
				fill="#e5e5e5"
				d="M711.063 12.828H713.063V18.828H711.063z"
			></path>

			<path
				fill="#e5e5e5"
				d="M713.063 312.588h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2zm0-24.48h-2v-12.24h2z"
			></path>

			<path
				fill="#e5e5e5"
				d="M711.063 324.828H713.063V330.828H711.063z"
			></path>

			<path
				fill="#e5e5e5"
				d="M382.226 97.828H384.226V103.828H382.226z"
			></path>

			<path
				fill="#e5e5e5"
				d="M384.226 313.197h-2v-11.632h2zm0-23.264h-2v-11.63h2zm0-23.263h-2V255.04h2zm0-23.263h-2v-11.631h2zm0-23.263h-2v-11.631h2zm0-23.263h-2v-11.632h2zm0-23.263h-2v-11.632h2zm0-23.264h-2v-11.631h2zm0-23.263h-2V115.46h2z"
			></path>

			<path
				fill="#e5e5e5"
				d="M382.226 324.828H384.226V330.828H382.226z"
			></path>

			<path
				fill="#3f3d56"
				d="M823.991 333.614h-662.12a2.072 2.072 0 01-2.073-2.072V14.187a2.072 2.072 0 014.145 0V329.47H823.99a2.072 2.072 0 110 4.144z"
			></path>

			<path
				fill="#3f3d56"
				d="M493.134 180.989L383.049 98.534 274.468 179.862 271.983 176.545 383.049 93.355 492.61 175.418 601.484 56.274 601.921 56.097 711.744 11.848 713.293 15.693 603.907 59.765 493.134 180.989z"
			></path>

			<circle
				cx="273.226"
				cy="178.204"
				r="12.433"
				fill={props.color}
			></circle>

			<circle
				cx="383.049"
				cy="97.39"
				r="12.433"
				fill={props.color}
			></circle>

			<circle
				cx="492.872"
				cy="178.204"
				r="12.433"
				fill="#e5e5e5"
			></circle>

			<circle
				cx="489.872"
				cy="222.204"
				r="12.433"
				fill={props.color}
			></circle>

			<circle
				cx="602.695"
				cy="58.02"
				r="12.433"
				fill={props.color}
			></circle>

			<circle
				cx="712.518"
				cy="12.433"
				r="12.433"
				fill={props.color}
			></circle>

			<ellipse
				cx="339.077"
				cy="523.38"
				fill="#2f2e41"
				rx="21.534"
				ry="6.76"
				transform="rotate(-69.082 40.391 517.944)"
			></ellipse>

			<circle
				cx="112.301"
				cy="273.407"
				r="43.067"
				fill="#2f2e41"
			></circle>

			<path
				fill="#2f2e41"
				d="M92.676 307.207H105.76V330.649H92.676z"
			></path>

			<path
				fill="#2f2e41"
				d="M118.843 307.207H131.927V330.649H118.843z"
			></path>

			<ellipse
				cx="103.579"
				cy="330.921"
				fill="#2f2e41"
				rx="10.903"
				ry="4.089"
			></ellipse>

			<ellipse
				cx="129.746"
				cy="330.376"
				fill="#2f2e41"
				rx="10.903"
				ry="4.089"
			></ellipse>

			<circle
				cx="113.391"
				cy="262.504"
				r="14.719"
				fill={props.color}
			></circle>

			<circle
				cx="113.391"
				cy="262.504"
				r="4.906"
				fill="#3f3d56"
			></circle>

			<path
				fill="#e6e6e6"
				d="M70.524 233.356c-3.477-15.574 7.64-31.31 24.829-35.149s33.944 5.675 37.422 21.249-7.915 21.318-25.105 25.156-33.668 4.318-37.146-11.256z"
			></path>

			<ellipse
				cx="250.761"
				cy="535.919"
				fill="#2f2e41"
				rx="6.76"
				ry="21.534"
				transform="rotate(-64.626 -66.043 542.475)"
			></ellipse>

			<path
				fill={props.color}
				d="M91.147 288.944c0 4.215 10.853 12.539 22.896 12.539s23.335-11.867 23.335-16.082-11.292.818-23.335.818-22.896-1.49-22.896 2.725z"
			></path>

			<circle
				cx="437.431"
				cy="266.554"
				r="43.067"
				fill="#2f2e41"
			></circle>

			<path
				fill="#2f2e41"
				d="M417.805 300.353H430.889V323.795H417.805z"
			></path>

			<path
				fill="#2f2e41"
				d="M443.972 300.353H457.056V323.795H443.972z"
			></path>

			<ellipse
				cx="614.676"
				cy="609.563"
				fill="#2f2e41"
				rx="4.089"
				ry="10.903"
				transform="rotate(-65.665 302.29 613.195)"
			></ellipse>

			<ellipse
				cx="639.844"
				cy="608.018"
				fill="#2f2e41"
				rx="4.089"
				ry="10.903"
				transform="rotate(-64.102 320.764 616.08)"
			></ellipse>

			<path
				fill={props.color}
				d="M395.654 226.502c-3.478-15.573 7.638-31.31 24.829-35.148s33.944 5.675 37.421 21.249-7.915 21.317-25.105 25.156-33.668 4.317-37.145-11.257z"
			></path>

			<ellipse
				cx="665.503"
				cy="524.928"
				fill="#2f2e41"
				rx="23.892"
				ry="7.501"
				transform="rotate(-45.022 231.203 609.248)"
			></ellipse>

			<ellipse
				cx="581.797"
				cy="564.928"
				fill="#2f2e41"
				rx="23.892"
				ry="7.501"
				transform="rotate(-75.137 304.69 545.21)"
			></ellipse>
		</svg>
	);
};
