import {
	Box,
	Flex,
	useColorMode,
} from "@chakra-ui/react";
import * as React from "react";

import Logo from "./assets/Logo";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import Drawer from "./Drawer";

export default (): JSX.Element => {
	const { colorMode } = useColorMode();

	return (
		<Flex
			paddingTop={8}
			mb={20}
			justifyContent={"space-between"}
		>
			<Box
				w={"200px"}
				flexShrink={1}
				alignItems={"flex-start"}
				display={"flex"}
			>
				<Logo color={colorMode === "dark" ? "#fff" : "#1a202c"} />
			</Box>

			<Box
				gap={3}
				display={"flex"}
			>
				<ColorModeSwitcher justifySelf="flex-end" />

				{/* <Drawer /> */}
			</Box>
		</Flex>
	);
};
