import {
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react";
import * as React from "react";

export default (): JSX.Element => (
	<Flex
		flexDir={"column"}
		alignItems={"center"}
		mb={12}
	>
		<Heading
			mb={2}
			size={"2xl"}
			fontWeight={"normal"}
		>
			Experts In Beating The Market
		</Heading>

		<Heading
			fontWeight={"normal"}
			fontSize="md"
		>
			Machine Learning - Market Analysis - Automated Trading
		</Heading>
	</Flex>
);
