import {
	ChakraProvider,
	Divider,
	Flex,
} from "@chakra-ui/react";
import * as React from "react";

import "@fontsource/raleway/400.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/source-code-pro/400.css";

import Background from "./Background";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header";
import TagLine from "./TagLine";
import theme from "./theme";

export const App = (): JSX.Element => {
	return (
		<ChakraProvider theme={theme}>
			<Background>
				<Flex
					justifySelf={"center"}
					justifyContent={"center"}
					flexDir={"column"}
					flex={1}
					maxWidth={1220}
					paddingLeft={5}
					paddingRight={5}
				>
					<Header />

					<TagLine />

					<Body />

					<Divider />

					<Footer />
				</Flex>
			</Background>
		</ChakraProvider>
	);
};
