import {
	Box,
	Button,
	Flex,
	Heading,
	Text,
	useColorMode,
} from "@chakra-ui/react";
import * as React from "react";

import Minions from "./assets/Minions";
import Nakamoto from "./assets/Nakamoto";

export default (): JSX.Element => {
	const { colorMode } = useColorMode();

	const mailTo = (type: string): void => {
		window.location.href = `mailto:web@lados.trade?body=${type}`;
	};

	return (
		<Flex
			as="main"
			role="main"
			direction="column"
			flex="1"
			py="16"
		>
			<Flex
				flexDir={"row"}
				alignItems={"center"}
				gap={25}
				flexWrap={"wrap"}
				justifyContent={"space-between"}
			>
				<Flex
					borderTopRightRadius={18}
					borderBottomLeftRadius={18}
					padding={8}
					flexGrow={1}
					backgroundColor={colorMode === "dark" ? "white" : "gray.800"}
					minWidth={330}
					maxWidth={500}
					height={620}
					justifyContent={"space-between"}
					flexDir={"column"}
				>
					<Box>
						<Heading
							color={colorMode === "dark" ? "gray.800" : "whiteAlpha.900"}
							textDecoration={"underline"}
							mb={4}
							fontSize={"3xl"}
						>
							Use Our Bespoke Bot
						</Heading>

						<Text
							color={colorMode === "dark" ? "gray.800" : "whiteAlpha.900"}
						>
							{`
								We've tailor made our own algotrading bot, running on crypto markets. 
							`}
						</Text>
					</Box>

					<Nakamoto color={colorMode === "dark" ? "#A0AEC0" : "#ccc"} />

					<Button
						colorScheme={"teal"}
						variant={"solid"}
						alignSelf={"flex-start"}
						size={"lg"}
						mt={6}
						onClick={() => mailTo("Bot Enquiry")}
					>
						I Want To Use It
					</Button>
				</Flex>

				<Flex
					borderTopLeftRadius={18}
					borderBottomRightRadius={18}
					padding={8}
					flexGrow={1}
					backgroundColor={colorMode === "dark" ? "white" : "gray.800"}
					minWidth={330}
					maxWidth={500}
					height={620}
					justifyContent={"space-between"}
					flexDir={"column"}
				>
					<Box>
						<Heading
							color={colorMode === "dark" ? "gray.800" : "whiteAlpha.900"}
							textDecoration={"underline"}
							mb={4}
							fontSize={"3xl"}
						>
							Available for Hire!
						</Heading>

						<Text
							color={colorMode === "dark" ? "gray.800" : "whiteAlpha.900"}
						>
							{`
								We can help with the implementation process of your strategies.
                                Get in touch to see how we could be a good fit.
							`}
						</Text>
					</Box>

					<Minions color={colorMode === "dark" ? "#A0AEC0" : "#fff"} />

					<Button
						colorScheme={"blue"}
						variant={"solid"}
						alignSelf={"flex-end"}
						size={"lg"}
						mt={6}
						onClick={() => mailTo("Hiring Enquiry")}
					>
						Message Us
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
