import { Box, ButtonGroup, IconButton, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import { FaGithub } from "react-icons/fa";

export default (): JSX.Element => {
	return (
		<Stack
			pt="8"
			pb="8"
			justify="space-between"
			direction={{ base: "column-reverse", md: "row" }}
			align="center"
		>
			<Box>
				<Text
					fontSize="sm"
					color="subtle"
				>
					&copy; {new Date().getFullYear()} Lados Ltd. All rights reserved.
				</Text>
			</Box>

			<ButtonGroup variant="ghost">
				<IconButton
					as="a"
					href="#"
					aria-label="GitHub"
					icon={<FaGithub fontSize="1.25rem" />}
					onClick={() => window.location.replace("https://github.com/Los-Lados")}
				/>
			</ButtonGroup>
		</Stack>
	);
};
