import {
	Flex,
	useColorMode,
} from "@chakra-ui/react";
import * as React from "react";

interface props {
    children: JSX.Element | JSX.Element[];
}

export default (props: props): JSX.Element => {
	const { colorMode } = useColorMode();

	return (
		<Flex
			bgGradient={colorMode === "dark" ? "linear(to top, gray.900, gray.800)" : "linear(to top, gray.100, white)"}
			flexGrow={1}
			justifyContent={"center"}
		>
			{props.children}
		</Flex>
	);
};
